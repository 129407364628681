
<template>
  <div  id="data-list-list-view" class="data-list-container">
     <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.push('/AccountManagerRate') "
    />

    <vs-table
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="AccountManagerRates"
    >
   <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4" >
         <div
         class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
         >
          <span class="mr-2">
                {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                AccountManagerRates.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : AccountManagerRates.length
                }}
              of {{ AccountManagerRates.length }}
              </span>

              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />

         </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
     </div>

       <template slot="thead">
        <vs-th key="AgentName">{{$t("AgentName")}}</vs-th>
        <vs-th key="Rate">{{$t("Rate")}}</vs-th>
         <vs-th key="Comment">{{$t("Comment")}}</vs-th>
       </template>
       <template slot-scope="{ data }">
         <tbody>
           <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td >
                <P class="product-name font-medium truncate" id="tr" >
                  {{tr.AccountManagerName}}
                  </P>
           </vs-td>

           <vs-td>
             <p class="product-name font-medium truncate" >
               {{tr.Rate}}</p>
           </vs-td>

           <vs-td lass="product-name font-medium truncate">
             {{tr.Comment}}
           </vs-td>

           </vs-tr>

         </tbody>

       </template>
    </vs-table>

   </div>
</template>

<script>

import moduleAccountManagerRate from '@/store/AccountManagerRate/moduleAccountManagerRate';


export default {
  components: {

  },
  data() {
    return {
     selected: [],
      AccountManagerRate:{},
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    AccountManagerRates(){
      return this.$store.state.AccountManagerRateList.AccountManagerRates;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    }

  },

  methods: {

  },

  created() {

       const ID = this.$route.params.AccountManagerID;
       if (!moduleAccountManagerRate.isRegistered) {
      this.$store.registerModule("AccountManagerRateList", moduleAccountManagerRate);
      moduleAccountManagerRate.isRegistered = true;
      }

     this.$store.dispatch("AccountManagerRateList/ViewAccountManagerRateHistory", ID );

    },

  mounted() {
    this.isMounted = true;
  }

};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
